




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchCreateFood } from '@/store/crud/actions';
import FoodForm from '@/components/forms/FoodForm.vue';
import { ICreateFood } from '@/interfaces/foods';


@Component({ components: { FoodForm } })
export default class Food extends AppComponent {
  @Prop({ type: String, required: false }) public foodId: string;

  public foodUpdated() {
    this.$router.push('/main/content/foods');
  }

  public async foodCreated(foodPayload: ICreateFood) {
    if (foodPayload && Object.keys(foodPayload).length > 0) {
      const result = await dispatchCreateFood(this.$store, foodPayload);
      if (result) {
          this.$router.push('/main/content/foods');
      }
    }
  }

  public cancel() {
    this.$router.push('/main/content/foods');
  }
}
